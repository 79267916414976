export default {
	FETCH_COURTS: (state, data) => {
		state.courts = data
	},
	FETCH_RESERVATIONS: (state, data) => {
		state.reservations = data
	},
	STORE_USER: (state, data) => {
		state.user.firstName = data.firstName
		state.user.lastName = data.lastName
		state.user.id = data.id
	},
	SET_LOGIN_STATE: (state, data) => {
		state.user.loginStatus = data
	},
	SIGN_OUT_USER: (state) => {
		state.user.loginStatus = false
		state.user.firstName = ""
		state.user.lastName = ""
		state.user.id = ""
	},
	SET_SHOW_INFO_SCREEN: (state, data) => {
		console.log('x', data);
		state.sessionSettings.showInfoScreen = data
	}
}