
// src/store/state.js
export default {
	courts: [
    {
      id: 1,
      name: 'Platz 1',
      color: 'xGreen1'
    },
    {
      id: 2,
      name: 'Platz 2',
      color: 'xYellow1'
    },
    {
      id: 3,
      name: 'Platz 3',
      color: 'xBlue1'
    },
    {
      id: 4,
      name: 'Platz 4',
      color: 'xRed'
    }
  ],
  reservations: [],
  user: {
    firstName: "",
    lastName: "",
    id: "",
    loginStatus: false
  },
  sessionSettings: {
    showInfoScreen: true
  }
}