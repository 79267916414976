import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import state from './state.js'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
	key: 'tc-magdalensberg-store',
	storage: window.localStorage,
	//reducer: (state) => ({products: state.user})
})

export default new Vuex.Store({
	state: state,
	mutations: mutations,
	actions: actions,
	getters: getters,
	plugins: [vuexLocal.plugin]
})
