//import moment from 'moment'

export default {
/* 	getAllCourts: (state) => {
		return state.courts
	},
	filteredReservations: (state) => (date, court) => {
		let filtered = state.reservations.filter(element => {
			if (element.date == date && element.platz == court) {
				return true
			}
		})
		console.log('filtered: ',filtered);
		let sorted = filtered.sort((a, b) => {
			return moment(a.von, 'hh:mm').isBefore(moment(b.von, 'hh:mm')) ? -1 : 1
		});
		return sorted
	} */
}
