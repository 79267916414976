
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/firestore';   // for cloud firestore


// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyC9RnPrQuB2lu-UENcNyOu2zzkf_IWn3sI",
    authDomain: "tc-magdalensberg-8c1e1.firebaseapp.com",
    projectId: "tc-magdalensberg-8c1e1",
    storageBucket: "tc-magdalensberg-8c1e1.appspot.com",
    messagingSenderId: "254501459772",
    appId: "1:254501459772:web:21e0fab21840015e05f039",
    measurementId: "G-P3HZFZJSVG"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth();
  const db = firebaseApp.firestore();

  export {
      auth,db
  }